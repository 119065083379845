// jQuery assets
import '/javascript/partials/jquery.js';
import '/javascript/partials/jquery-parsleyjs.js';
import '/javascript/partials/jquery-slick.js';
import '/javascript/partials/jquery-stickykit.js';

// Google assets
import '/javascript/partials/google-font.js';
import '/javascript/partials/google-map.js';

// Other assets
import '/javascript/partials/aos.js';

// Modules
import '/javascript/modules/01-navigation.js';
import '/javascript/modules/02-helper.js';
import '/javascript/modules/03-components.js';

// SCSS
import '/scss/global.scss';
