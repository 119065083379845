// JS depedency
var GoogleMapsLoader = require('google-maps');

// Initialisation
GoogleMapsLoader.KEY = 'AIzaSyDe__2HBGScR7QDvPGvHYeOvOWoKpzrDOo';

GoogleMapsLoader.load(function(google) {

  gmap_simple = function() {

    function gmap_simple_init() {
      var map;
      var mapLat =  14.618929;
      var mapLng = -61.060379;
      var mapZoom = 14;
      var myLatLng = {
        lat: mapLat,
        lng: mapLng
      };

      map = new google.maps.Map(document.getElementById("gmap-simple"), {
        mapTypeId: 'roadmap',
        scrollwheel: false,
        panControl: false,
        zoomControl: true,
        zoom: mapZoom,
        center: myLatLng,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false
      });

      var marker = new google.maps.Marker({
        position: myLatLng,
        map: map,
        title: 'Hello World!'
      });
    }

    gmap_simple_init();
  }

  gmap_cluster = function() {

    var markerCluster = require('js-marker-clusterer');

    var map = new google.maps.Map(document.getElementById('gmap-cluster'), {
      zoom: 2,
      center: {
        lat: 31.857457,
        lng: -40.820324
      }
    });

    var infoWin = new google.maps.InfoWindow();
    var markers = locations.map(function(location, i) {
      var marker = new google.maps.Marker({
        position: location
      });
      google.maps.event.addListener(marker, 'click', function(evt) {
        infoWin.setContent(location.info);
        infoWin.open(map, marker);
      });
      return marker;
    });

    var markerCluster = new MarkerClusterer(map, markers, {
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    });
  }

  if ($('#gmap-simple').length) {
    gmap_simple();
  }
  if ($('#gmap-cluster').length) {
    gmap_cluster();
  }

});


$('#form-contact .btn-wp').on('click', function(){
  $('#form-contact .btn-wp').removeClass('active');
  $(this).addClass('active');
  var number = $(this).data('trigger');
  $('#form-1,#form-2').hide();
  $('#form-'+number).show();
  return false;
});

$('#form-contact .btn-wp[data-trigger=1]').trigger('click');
